 // ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit"
import {hideModalCustom } from "../../../redux/modalTypeCustom"

import SuccessToast from "../../../components/common/toast/SuccessToast"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { toast } from "react-toastify"
import { ToastSuccess } from "../../../assets/images/icons"

//api url
import { front_base_url } from "@src/api/config"

export const downloadAllProfiles = createAsyncThunk(
    "quotations/downloadAllProfiles",
    async (row, {dispatch}) => {
        try {
          const res = await fetch(`${front_base_url}/api/quotations/${row.id}/downloadAllProfiles`)
          if (res.ok) {
              const header = res.headers.get('Content-Disposition')
              const parts = header ? header.replaceAll('"', '').split(';') : null
              const filename = parts ? parts[1].split('=')[1] : "replicated_quotatios.zip"
              const blob = await res.blob()
              dispatch(hideModalCustom())
              toast(<SuccessToast message={"Descarga exitosa"} />, {
                  icon: <ToastSuccess />,
                  hideProgressBar: true,
                  className: "toast-success",
                  autoClose: 5000
              })
              return {
                  blob,
                  filename
              }
          }
        } catch (error) {
          toast(<ErrorToast message={"No fue posible completar la descarga"} />, {
            icon: <ErrorToast />,
            hideProgressBar: true,
            className: "toast-danger",
            autoClose: 8000
          })
        }
    }
  )