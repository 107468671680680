import trash_icon from "./trash.svg"
import watch_icon, { ReactComponent as Watch } from "./watch.svg"
import edit_icon, { ReactComponent as Edit } from "./edit.svg"
import edit_green_icon from "./edit_green.svg"
import link_icon, { ReactComponent as Link } from "./link.svg"
import { ReactComponent as shield } from "./shield.svg"
import { ReactComponent as ambulance } from "./ambulance_white.svg"
import { ReactComponent as catalog } from "./catalogs.svg"
import { ReactComponent as pin } from "./pin.svg"
import { ReactComponent as star } from "./star.svg"
import { ReactComponent as transport } from "./Transport.svg"
import { ReactComponent as calendar } from "./calendar.svg"
import { ReactComponent as Maps } from "./map.svg"
import { ReactComponent as Target } from "./target.svg"
import { ReactComponent as Medal } from "./medal.svg"
import { ReactComponent as Document } from "./document.svg"
import { ReactComponent as Billing } from "./billing.svg"
import { ReactComponent as Bank } from "./bank.svg"
import { ReactComponent as Info } from "./info_icon.svg"
import { ReactComponent as Share } from "./share_icon.svg"
import { ReactComponent as Security } from "./security.svg"
import { ReactComponent as CopyText } from "./copy-text.svg"
import { ReactComponent as Check } from "./check.svg"
import { ReactComponent as Reject } from "./reject.svg"
import danger_icon, { ReactComponent as Danger } from "./danger.svg"
import success_icon, { ReactComponent as Success } from "./success.svg"
import { ReactComponent as Mail } from "./mail.svg"
import { ReactComponent as Download } from "./download.svg"
import { ReactComponent as SquareDollar } from "./square-dollar.svg"
import { ReactComponent as Plus } from "./plus.svg"
import { ReactComponent as Minus } from "./minus-circle.svg"
import { ReactComponent as Cert } from "./cert.svg"
import { ReactComponent as Phone } from "./phone.svg"
import { ReactComponent as X } from "./x.svg"
import { ReactComponent as SuitCase } from "./suitcase.svg"
import { ReactComponent as Payment } from "./payment.svg"
import { ReactComponent as OpenPayIcon } from "./payment_mark.svg"
import { ReactComponent as AllowTheseCards } from "./payment_cards.svg"
import { ReactComponent as ToastSuccess } from "./toast_success.svg"
import { ReactComponent as Rectangle } from "./rectangle.svg"
import { ReactComponent as TransferCards } from "./transfer_cards.svg"
import { ReactComponent as PoweredByOpenPay } from "./openpay.svg"
import { ReactComponent as StoreLogos } from "./logos.svg"
import { ReactComponent as Money } from "./money.svg"
import { ReactComponent as Building } from "./building.svg"
import { ReactComponent as Warning } from "./warning.svg"
import { ReactComponent as Home } from "./home.svg"
import { ReactComponent as Card } from "./card.svg"
import { ReactComponent as Transfer } from "./transfer.svg"
import { ReactComponent as Reference } from "./reference.svg"
import { ReactComponent as Clock } from "./clock.svg"
import { ReactComponent as Cancel } from "./close.svg"
import { ReactComponent as DropzoneIcon } from "./dropzone.svg"
import { ReactComponent as SpinnerIcon } from "./spinner.svg"
import { ReactComponent as PDFIcon } from "./pdf.svg"
import { ReactComponent as PNGIcon } from "./png_file.svg"
import { ReactComponent as JPEGIcon } from "./jpeg_file.svg"
import { ReactComponent as DownloadFileIcon } from './download-file.svg'
import { ReactComponent as PayHand } from './pay_hand.svg'
import { ReactComponent as Exclamation } from './exclamation.svg'
import { ReactComponent as ErrorCar } from './error_car.svg'
import { ReactComponent as Question } from './question.svg'
import { ReactComponent as QuestionSeller } from './question_seller.svg'
import { ReactComponent as FileAlert } from './file_alert.svg'
import { ReactComponent as AmountChange } from './amount_change.svg'
import { ReactComponent as FrameGreen } from './frame_green.svg'
import { ReactComponent as HeartRate } from './heart_rate.svg'
import { ReactComponent as HealthBox } from './health_box.svg'
import { ReactComponent as FrameGray } from './frame_gray.svg'
import { ReactComponent as EyeReport } from './eye.svg'
import { ReactComponent as TurnDownRight } from './turn_down_right.svg'
import { ReactComponent as FieldChanged } from './field_changed.svg'
import { ReactComponent as ResumeLoader } from './resume_loader.svg'
import { ReactComponent as Alert } from './alert.svg'
import { ReactComponent as FileError } from './file_error.svg'
import { ReactComponent as InvoiceMenu } from './invoices-menu.svg'
import { ReactComponent as PaymentMenu } from './payments-menu.svg'
import { ReactComponent as WhatsApp } from "./whatsapp.svg"
import { ReactComponent as Invoice } from './invoice.svg'
import { ReactComponent as CreditCard } from './credit-card.svg'
import { ReactComponent as EquipoElectronico } from './EquipoElectronico.svg'
import { ReactComponent as Cargas } from './Cargas.svg'
import { ReactComponent as AccidentesPersonales } from './AccidentesPersonales.svg'
import { ReactComponent as RCContratistas } from './RCContratistas.svg'
import { ReactComponent as ERN } from './CargasERN.svg'//MISMO LOGO PARA ERN
import { ReactComponent as Saldo } from './saldo.svg'
import { ReactComponent as Lock } from "./lock.svg"
import { ReactComponent as Chip } from "./chip.svg"
import { ReactComponent as Plan } from "./plan.svg"
import { ReactComponent as Constructor } from "./constructor.svg"
import { ReactComponent as ResumeIssued } from "./resume_issued.svg"
import { ReactComponent as ResumeCurrent } from "./resume_current.svg"
import { ReactComponent as ResumeToExpired } from "./resume_to_expired.svg"
import { ReactComponent as ResumeFullPremium } from "./resume_full_premium.svg"
import { ReactComponent as EyeBox } from "./eye_box.svg"
import { ReactComponent as Renovacion } from "./repeat.svg"
import { ReactComponent as FileFolder } from "./file_folder.svg"
import { ReactComponent as ChatInfo } from "./chat_info.svg"
import { ReactComponent as UploadIcon } from "./upload.svg"
import { ReactComponent as CheckCircleIcon } from "./check_circle.svg"
import { ReactComponent as CSVIcon } from "./csv.svg"
import { ReactComponent as Down } from "./Down.svg"
import { ReactComponent as Up } from './Up.svg'
import subAgrupar_icon from "./Sub-agrupar.svg"
import { ReactComponent as DownloadAllIcon } from "./download-cloud.svg"

export const SubAgruparIcon = subAgrupar_icon
export const trash = trash_icon
export const watch = watch_icon
export const edit = edit_icon
export const link = link_icon
export const success = success_icon
export const error = danger_icon
export const edit_green = edit_green_icon
export const Shield = shield
export const Ambulance = ambulance
export const Catalogs = catalog
export const Pin = pin
export const Star = star
export const Transport = transport
const Bus = transport
export const Calendar = calendar
export {
  DownloadAllIcon,
  Down,
  Up,
  Maps,
  Target,
  Medal,
  Document,
  Bus,
  Billing,
  Bank,
  Info,
  Share,
  Security,
  CopyText,
  Watch,
  Edit,
  Check,
  Reject,
  Danger,
  Success,
  Mail,
  Download,
  DownloadFileIcon,
  SquareDollar,
  Plus,
  Minus,
  Cert,
  Phone,
  X,
  SuitCase,
  Payment,
  OpenPayIcon,
  AllowTheseCards,
  ToastSuccess,
  Rectangle,
  TransferCards,
  PoweredByOpenPay,
  StoreLogos,
  Money,
  Building,
  Warning,
  Home,
  Card,
  Transfer,
  Reference,
  Clock,
  Cancel,
  DropzoneIcon,
  SpinnerIcon,
  PDFIcon,
  PayHand,
  PNGIcon,
  JPEGIcon,
  Exclamation,
  ErrorCar,
  Question,
  QuestionSeller,
  FileAlert,
  AmountChange,
  FrameGreen,
  HeartRate,
  HealthBox,
  FrameGray,
  EyeReport,
  TurnDownRight,
  FieldChanged,
  ResumeLoader,
  Alert,
  FileError,
  InvoiceMenu,
  PaymentMenu,
  WhatsApp,
  Invoice,
  CreditCard,
  EquipoElectronico,
  Cargas,
  AccidentesPersonales,
  RCContratistas,
  ERN,
  Saldo,
  Lock,
  Chip,
  Plan,
  Constructor,
  ResumeIssued,
  ResumeCurrent,
  ResumeToExpired,
  ResumeFullPremium,
  EyeBox,
  Renovacion,
  FileFolder,
  ChatInfo,
  UploadIcon,
  CheckCircleIcon,
  CSVIcon
}
