// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { Cert } from '../assets/images/icons'
export const modalTypeCertRenewalOptions = createSlice({
  name: 'modals_custom',
  initialState: {
    toggle: false,
    loading: false
  },
  reducers: {
    showModalCertRenewalOptions: (state) => {
      state.toggle = true
      state.loading = false
    },
    hideModalCertRenewalOptions: (state) => {
      state.toggle = false
      state.loading = false
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const { showModalCertRenewalOptions, hideModalCertRenewalOptions, setLoading } = modalTypeCertRenewalOptions.actions
export default modalTypeCertRenewalOptions.reducer
